import React from 'react';
import classnames from 'classnames';
import { branch, renderComponent } from 'recompose';
import styles from '../styles/components/list.module.scss';
import { AffiliateIcon } from './icons/affiliate-icon';
import { Link } from './link';

export const Label = React.memo(({ children, className, grow }) => (
  <span className={classnames(styles.label, className, { [styles.grow]: grow })}>{children}</span>
));

export const SubTitle = React.memo(({ children, className }) => (
  <span className={classnames(styles['sub-title'], className)}>{children}</span>
));

export const Value = React.memo(({ children, className }) => (
  <span className={classnames(styles.value, className)}>{children}</span>
));

export const TextBox = React.memo(({ children, className }) => (
  <div className={classnames(styles.text, className)}>{children}</div>
));

export const ContainerAsBox = React.memo(({ children, className, onClick, withIcon, icon = 'checklist-action' }) => (
  <div className={classnames(styles.item, className)} onClick={onClick}>
    {children}
    {withIcon && <AffiliateIcon className={styles.icon} inline name={icon} />}
  </div>
));

export const ContainerAsLink = React.memo(({ to, children, onClick, className, icon = 'checklist-action' }) => (
  <Link className={classnames(styles.item, className)} to={to} onClick={onClick}>
    {children}
    {icon && <AffiliateIcon className={styles.icon} inline name={icon} />}
  </Link>
));

export const Container = branch(({ to }) => to, renderComponent(ContainerAsLink))(ContainerAsBox);

export const ListItem = React.memo(({ label, children, className, ...props }) => (
  <li className={className}>
    {label ? (
      <Container to={label.to} {...props}>
        {label.title && <Label>{label.title}</Label>}
        {label.value && <Value>{label.value}</Value>}
      </Container>
    ) : (
      children
    )}
  </li>
));
export const List = React.memo(({ children, className }) => <ul className={className}>{children}</ul>);
